import React, { useContext } from 'react'
import { useCart } from 'react-use-cart'
import AppContext from '../store/AppContext'

function Totals() {
    const {items,cartTotal} = useCart()
    const {clientSecret} = useContext(AppContext)

  return (
    <div className="card">
        <div className="card-header tickets-card-header">
            <span className="tickets-card-header-title">
                Your Cart
            </span>
        </div>
        <div className="card-body tickets-card-body">
            <div className="tickets-card-body-heading">
                <span>League ITEMS</span>
            </div>
            <div className="tickets-card-items">
                {items.length ? items.map((item)=>{
                    return <div className="tickets-card-item">
                        <span>{item.name}</span>
                        <span>{item.quantity}</span>
                    </div>
                }) : <div className='py-3 text-center'>
                        <span style={{color: '#686868'}}>Cart is empty!</span>
                </div>}
                <div className="tickets-card-item-price">
                    <span>Estimated Price</span>
                    <span>$ {cartTotal}/-</span>
                </div>
                {!clientSecret && <div className="tickets-card-item-btn">
                    <button className="btn" data-bs-toggle="modal" data-bs-target="#exampleModal" disabled={items.length <= 0}>PROCEED</button>
                </div>}
            </div>
        </div>
    </div>
  )
}

export default Totals