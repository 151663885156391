import React, { createContext, useEffect, useReducer } from 'react'
import axios from 'axios'
import { useCart } from 'react-use-cart'

const initialState = {
  activeStage: 'leagues',
  clientSecret: null,
  email: null,
}

const reducer = (state, action) => {
    switch (action.type) {
      case "CHANGE_STAGE":
        return {
            ...state,
            activeStage: action.payload
        }
      case "SET_CLIENT_SECRET":
        return {
            ...state,
            clientSecret: action.payload
        }
      case "SET_EMAIL":
        return {
            ...state,
            email: action.payload
        }
      default: {
          return { ...state }
      }
    }
}

const AppContext = createContext({
    ...initialState,
    changeStage: (name) => Promise.resolve(),
    placeOrder: (orderDists) => Promise.resolve(),
    completeOrder: () => Promise.resolve(),
    changeEmail: () => null
})

export const AppProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState)
    const {cartTotal,items,emptyCart} = useCart()

    function changeStage(name){
        dispatch({
            type: 'CHANGE_STAGE',
            payload: name
        })
    }

    function changeEmail(email){
        dispatch({
            type: 'SET_EMAIL',
            payload: email
        })
    }

    async function placeOrder(orderDists,id){
        console.log(orderDists)
        let leagueId = id;
        let clientEmail = state.email;

        const order = {
            leagueId,
            clientEmail,
            orderDists: [...orderDists.fields]
        }

        try {
            axios.post(process.env.REACT_APP_API_URL + '/api/checkout',order).then((data)=>{
                const {status,object,message} = data.data
                if(status === 'Success'){
                    axios.get(process.env.REACT_APP_API_URL + '/api/checkout/clientsecret?paymentIntentId='+object).then((data)=>{
                        const {object,message,status} = data.data
                        if(status === 'Success'){
                            dispatch({
                                type: 'SET_CLIENT_SECRET',
                                payload: object
                            })
                            changeStage('checkoutForm')
                        }
                    })
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    async function completeOrder(paymentIntentId){
        axios.get(process.env.REACT_APP_API_URL + '/api/checkout/completeOrder?paymentIntentId='+paymentIntentId).then((data)=>{
            if(data.data.status === 'Error'){
                //When error
                console.log(data.data.message)
                dispatch({
                    type: 'SET_CLIENT_SECRET',
                    payload: null
                })
            }else if(data.data.status === 'Success'){
                dispatch({
                    type: 'SET_CLIENT_SECRET',
                    payload: null
                })
                changeStage('success')
            }
        }).catch((err)=>{
            console.log(err)
        })
    }

    useEffect(() => {
        ; (async () => {
            try {
                    // let id = new URLSearchParams(window.location.search).get('Id')
                    //create-test-2

            } catch (err) {
                // dispatch({type: 'INIT'})
            }
            emptyCart()
        })()
    }, [])

    return (
        <AppContext.Provider
            value={{
                ...state,
                changeStage,
                placeOrder,
                completeOrder,
                changeEmail
            }}
        >
            {children}
        </AppContext.Provider>
    )
}

export default AppContext