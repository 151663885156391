import React, { useContext, useState } from 'react'
import {PaymentElement,useElements,useStripe} from '@stripe/react-stripe-js';
import AppContext from '../../store/AppContext';

const CheckoutForm = () => {

    const elements = useElements()
    const stripe = useStripe()
    const [errorMessage, setErrorMessage] = useState(null)
    const {completeOrder} = useContext(AppContext)
    const [isProcessing, setIsProcessing] = useState(false)

    const handleSubmit = async (event) => {
        setIsProcessing(true)
        // We don't want to let default form submission happen here,
        // which would refresh the page.
        event.preventDefault();
    
        if (!stripe || !elements) {
          // Stripe.js has not yet loaded.
          // Make sure to disable form submission until Stripe.js has loaded.
          return;
        }
    
        const {error,paymentIntent} = await stripe.confirmPayment({
          //`Elements` instance that was used to create the Payment Element
          elements,
          redirect: 'if_required'
        //   confirmParams: {
        //     return_url: 'http://localhost:3000/?Id=create-test-2',
        //   },
        });
    
        if (error) {
          // This point will only be reached if there is an immediate error when
          // confirming the payment. Show error to your customer (for example, payment
          // details incomplete)
          console.log(error)
          setIsProcessing(false)
          setErrorMessage(error.message);
        } else {
          // Your customer will be redirected to your `return_url`. For some payment
          // methods like iDEAL, your customer will be redirected to an intermediate
          // site first to authorize the payment, then redirected to the `return_url`.
          setIsProcessing(false)
          completeOrder(paymentIntent.id)
        }
      };

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement options={{layout: { type: 'tabs', defaultCollapsed: false}}}/>
      <div className='mt-3 text-center'>
        {errorMessage && <p style={{color:'#F85431'}}>{errorMessage}</p>}
      </div>
      <div className='payment-btn mt-3 text-center'>
        <button className='btn' style={{width:'15rem'}} disabled={!stripe}>
            {isProcessing ? (
                <div class="spinner-border" role="status"></div>
            ) : 'Pay Now'}
        </button>
      </div>
    </form>
  );
};

export default CheckoutForm;