import React, { useContext, useEffect, useReducer } from 'react'
import { useCart } from 'react-use-cart'
import AppContext from '../store/AppContext';
import { useSearchParams } from 'react-router-dom';

const initialFields = [
    {
        "distId": 1,
        "qty": 1,
        "orderDistExtraFields": [
            [
                {
                    "extraFieldId": 1,
                    "value": "01 Testing 01"
                },
                {
                    "extraFieldId": 2,
                    "value": "01 Testing 02"
                }
            ],
            [
                {
                    "extraFieldId": 1,
                    "value": "02 Testomg 01"
                },
                {
                    "extraFieldId": 2,
                    "value": "02 Testing 02"
                }
            ]
        ]
    }
];

const fieldsReducer = (state, action) => {
    console.log("State>>",state)
    switch (action.type) {
        case 'INIT_FIELDS':{
            let fields = action.payload.map((item)=>{
                let obj = {},orderDistExtraFields = [];
                obj.distId = item.id;
                obj.qty = item.quantity;
                for(let i = 0; i < item.quantity; i++){
                    let qtyField = [];
                    item.extrafields.forEach((extrafield)=>{
                        qtyField.push({extraFieldId:extrafield.id,value: null })
                    })
                    orderDistExtraFields.push(qtyField)
                }
                obj.orderDistExtraFields = orderDistExtraFields;
                return obj;
            })
            console.log("Fields",fields)
            return {
                fields
            }
        }
        case 'CHANGE_FIELD':{
            let fields = state.fields.map((item)=>{
                if(item.distId === action.payload.distId){
                    let orderDistExtraFields = item.orderDistExtraFields.map((distExtraField,index)=>{
                        if(index === action.payload.qtyId){
                            let extraFieldItems = distExtraField.map((extraFieldItem)=>{
                                if(extraFieldItem.extraFieldId === action.payload.fieldId){
                                    return {...extraFieldItem,value: action.payload.value}
                                }else{
                                    return extraFieldItem
                                }
                            })
                            return extraFieldItems
                        }else{
                            return distExtraField
                        }
                    })
                    return {...item,orderDistExtraFields:[...orderDistExtraFields]}
                }else{
                    return item;
                }
            })
            console.log("Fields>.>>",fields)
            return {fields}
        } 
        default:
            return {...state}
    }
}

function ExtraFields() {
    const [fields, dispatch] = useReducer(fieldsReducer, initialFields);
    const {items} = useCart()
    const {placeOrder} = useContext(AppContext)
    const [searchParams] = useSearchParams()

    function handleSumbit(e){
        e.preventDefault()
        placeOrder(fields,searchParams.get('id'))
    }

    function changeField(distId,fieldId,qtyId,value){
        dispatch({
            type: 'CHANGE_FIELD',
            payload: {distId,fieldId,qtyId,value}
        })
    }

    function renderFields(item){

        let fieldItems = [];
        for(let i = 0; i < item.quantity; i++){
            fieldItems.push(<div className='row'>
            {item.extrafields.map((field,index)=>{
                if(field.dataType === 'Checkbox'){
                    return <div className='col-md-12 mb-3 form-check'>
                    <input type={field.dataType} className="form-check-input" onChange={(e)=>changeField(item.id,field.id,i,e.currentTarget.checked)} id={"extrafield"+index} placeholder={field.placeholder} required={field.isRequired}/>
                    <label htmlFor={"extrafield"+index} className="form-label">{field.name}<span style={{color:'red'}}> {field.isRequired && '*'}</span></label>
                </div>
                }else{
                    return <div className='col-md-6 mb-3'>
                    <label htmlFor={"extrafield"+index} className="form-label" >{field.name}<span style={{color:'red'}}> {field.isRequired && '*'}</span></label>
                    <input type={field.dataType} className="form-control" onChange={(e)=>changeField(item.id,field.id,i,e.currentTarget.value)} id={"extrafield"+index} placeholder={field.placeholder} required={field.isRequired}/>
                </div>
                }
            })}
            {i < (item.quantity-1) && <hr/>}
        </div>)
        }

        return <div className='container'>
            {fieldItems}
        </div>
    }

    useEffect(()=>{
        dispatch({
            type: 'INIT_FIELDS',
            payload: items
        })
        console.log("Items>>",items)
    },[])
  return (
    <form onSubmit={handleSumbit}>
    <div className="row my-5">
        <div className='col-md-12'>
            <h4 className='text-center text-white'>Please Fill in extra information.</h4>
        </div>
        <div className="col-md-12">
            
        {items.map((item,i)=>{
            if(item.extrafields.length <= 0) return ''
            return <div className="card mb-3" key={i}>
                <div className="card-body">
                    <h5 className="card-title">{item.name}</h5>
                    {/* <h6 className="card-subtitle mb-2 text-muted">Card subtitle</h6> */}
                    {renderFields(item)}
                </div>
            </div>
        })}
        </div>
        <div className='tickets-card-item-btn'>
            <button className="btn">PAY NOW</button>
        </div>
    </div>
    </form>
  )
}

export default ExtraFields