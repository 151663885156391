import React, { useContext, useState } from 'react'
import { useCart } from 'react-use-cart'
// import ArrowUpIcon from '../Assets/up-arrow.svg'
// import CrossIcon from '../Assets/cross.svg'
import AppContext from '../store/AppContext'

function StickyTotals() {
    const {items,cartTotal} = useCart()
    const {clientSecret} = useContext(AppContext)
    const [show,setShow] = useState(false)

  return (
    <div className="card">
    {show && <>
    <div className="card-header tickets-card-header">
        <span className="tickets-card-header-title">
            Your Tickets <span style={{float:'right'}} onClick={()=>setShow(!show)}>
                {/* <img src={CrossIcon} style={{width:'24px'}} /> */}
            </span>
        </span>
    </div>
    <div className="card-body tickets-card-body">
        <div className="tickets-card-body-heading">
            <span>EVENT & Other TICKETS</span>
        </div>
        <div className="tickets-card-items">
            {items.length ? items.map((item)=>{
                return <div className="tickets-card-item">
                    <span>{item.name}</span>
                    <span>{item.quantity}</span>
                </div>
            }) : <div className='py-3 text-center'>
                    <span style={{color: '#686868'}}>Cart is empty!</span>
            </div>}
        </div>
    </div>
    </>}
    {!show && <div className='tickets-cart-item-opener' onClick={()=>setShow(!show)}>
        {/* <img src={ArrowUpIcon} /> */}
    </div>}
    <div className="tickets-card-item-price">
        <span>Estimated Price</span>
        <span>$ {cartTotal}/-</span>
    </div>
    {!clientSecret && <div className="tickets-card-item-btn">
        <button className="btn" data-bs-toggle="modal" data-bs-target="#exampleModal" disabled={items.length <= 0}>PROCEED</button>
    </div>}
</div>
  )
}

export default StickyTotals