import React from 'react'

function PaymentSuccess() {
  return (
    <div className="vh-50 d-flex flex-column justify-content-center align-items-center">
        <div className="mb-4 text-center">
            <svg xmlns="http://www.w3.org/2000/svg" className="bi bi-check-circle-fill text-success" width="75" height="75"
                fill="currentColor" viewBox="0 0 16 16">
                <path
                    d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
            </svg>
        </div>
        <div className="text-center text-white">
            <h1>Thank You !</h1>
            <p>We've send the link to your inbox. Lorem ipsum dolor sit, </p>
            <div className='tickets-card-item-btn'>
                <a href='https://idevelopedskills.com/' className="btn">Back Home</a>
            </div>
        </div>
    </div>
  )
}

export default PaymentSuccess