import React from 'react'
import { useContext, useEffect, useState } from 'react';
import LeagueItems from '../components/LeagueItems'
import Totals from '../components/Totals'
import StickyTotals from '../components/StickyTotals'
import ExtraFields from '../components/ExtraFields'
import Checkout from '../components/Checkout';
import AppContext from '../store/AppContext';
import RefundPolicyModal from '../components/RefundPolicyModal';
import axios from 'axios';
import { useSearchParams } from 'react-router-dom';
import PaymentSuccess from '../components/PaymentSuccess';

function Root() {
    const [searchParams]  = useSearchParams()
    const {activeStage} = useContext(AppContext)
    const [distributions, setDistributions] = useState([])
  
    function renderStages(){
      switch (activeStage) {
        case 'leagues':
          return (<>
            <div className="row my-5">
                <div className="col-md-8">
                  <LeagueItems items={distributions}/>
                </div>
                <div className="col-md-4 d-none d-md-block">
                  <Totals/>
                </div>
            </div>
            <div className="sticky-totals">
                  <StickyTotals/>
            </div>
            <RefundPolicyModal/>
          </>)
        case 'extraFields':
          return (
            <ExtraFields/>
          )
        case 'checkoutForm':
          return (
            <Checkout/>
          )
        case 'success':
            return <PaymentSuccess/>
        default:
          return '';
      }
    }

    useEffect(()=>{
        axios.get(process.env.REACT_APP_API_URL+'/api/checkout?id='+searchParams.get('id')).then((res)=>{
          const {data} = res;
          setDistributions(data.distributions);
          console.log("Data>>",data)
        }).catch((err)=>{
          console.log("ERROR>>",err)
        })
      },[])
  return (
    <div className="container">
        {renderStages()}
    </div>
  )
}

export default Root