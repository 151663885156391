import React, { useContext, useEffect } from 'react'
import {Elements} from '@stripe/react-stripe-js';
import {loadStripe} from '@stripe/stripe-js';
import AppContext from '../../store/AppContext';
import CheckoutForm from './CheckoutForm';

const stripePromise = loadStripe('pk_test_51Mi0f1EKhnOa2BFkqSWK8WfMVxZ7sxe2jav39CQ7kFIa0WlNXWjp4codBu0WsA4KJHEqx6kTwurhhAN7WIWpHytT00g8YrYC0v');

function StripeContainer() {
      const {clientSecret} = useContext(AppContext) 

      const options = {
        // passing the client secret obtained from the server
        clientSecret: clientSecret
      };

      useEffect(()=>{
        console.log(clientSecret)
      },[])
    
      return (
        <Elements stripe={stripePromise} options={options}>
          <CheckoutForm />
        </Elements>
      );
}

export default StripeContainer