import React, { useContext, useEffect } from 'react'
import { useState } from 'react'
import AppContext from '../store/AppContext'

function RefundPolicyModal() {

    const {placeOrder,changeEmail,changeStage} = useContext(AppContext)
    const [agree,setAgree] = useState(false)
    const [isValid, setIsValid] = useState(false)
    const [isLoggedIn, setIsLoggedIn] = useState(false)
    const [email,setEmail] = useState('')
    const [confirmEmail, setConfirmEmail] = useState('')

    function handleEmailChange(e){
        setEmail(e.target.value)
    }

    function handleConfirmEmailChange(e){
        setConfirmEmail(e.target.value)
    }

    function handleProceed(){
        // placeOrder()
        changeStage('extraFields')

        let refundModalEl = document.getElementById('exampleModal');
        let refundModal = window.bootstrap.Modal.getInstance(refundModalEl)
        refundModal.hide()
    }

    useEffect(()=>{
        if(window?.appData?.loginStatus){
            setIsLoggedIn(true)
        }
    })

    useEffect(()=>{
        if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(email) && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(confirmEmail)){
            if(email === confirmEmail && agree){
                setIsValid(true)
                changeEmail(email)
            }else{
                setIsValid(false)
            }
        }else{
            setIsValid(false)
        }
        if(isLoggedIn && agree){
            setIsValid(true)
        }
    },[email,confirmEmail,agree])

  return (
    <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog modal-lg modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
            <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Refund Policy</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div className="modal-body">
                <p className="justify-content-evenly text-black">
                </p><h5 className="fw-bold">Buying Tickets:</h5>
                Buyers are solely responsible for completing their transactions in their purchase of Tickets, including the payment of applicable fees.
                By committing to purchase a Ticket, you are entering into a binding contract with the Seller to purchase the applicable Ticket.
                <strong>All sales are final, and except for cancelled events, you will not receive a refund for any completed purchase.</strong> You cannot change or cancel orders after the sale is complete.
                <br/>
                <br/>
                <h5 className="fw-bold">Agreement:</h5>
                You agree to supply Seller and EazyTicks with the appropriate information necessary for delivery of tickets, including, but not limited to, email address and physical address (if applicable). If you provide incorrect information for the delivery of Tickets, or you are unavailable to receive the tickets when they are delivered, you will not be eligible for a refund or Buyer Guarantee.
                <br/>
                <br/>
                <h5 className="fw-bold">Number of Tickets or “Ticket Limits”:</h5>
                In an effort to give all interested customers an opportunity to purchase Tickets to an event or other items via the Service and to discourage unfair Ticket buying practices, our clients often set limits on the number of seating and/or parking rights, or tickets a single customer may purchase. You will be advised of any such limits during the purchase process, or by a system limitation on the number of Tickets you may purchase during your online session.
                <p></p>
            </div>
            <div className="modal-footer justify-content-flex-start">
                <div className="form-check w-100">
                    <input className="form-check-input" type="checkbox" checked={agree} id="flexCheckChecked" onChange={(e)=>setAgree(e.target.checked)} />
                    <label className="form-check-label" for="flexCheckChecked">
                        Agree to the terms and conditions
                    </label>
                </div>
                {agree && !isLoggedIn && <div className='row w-100'>
                    <div className='col-12 col-md-6'>
                        <div className="mb-3">
                            <label for="emailFormControlInput" className="form-label">Email</label>
                            <input type="email" className="form-control" id="emailFormControlInput" value={email} onChange={(e)=>handleEmailChange(e)} placeholder="name@example.com" />
                        </div>
                    </div>
                    <div className='col-12 col-md-6'>
                        <div className="mb-3">
                            <label for="confirmEmailFormControlInput" className="form-label">Confirm Email</label>
                            <input type="email" className="form-control" id="confirmEmailFormControlInput" value={confirmEmail} onChange={(e)=>handleConfirmEmailChange(e)} placeholder="name@example.com" />
                        </div>
                    </div>
                </div>}
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" className="btn btn-primary" onClick={()=>handleProceed()} disabled={!isValid}>Pay Now</button>
            </div>
            </div>
        </div>
    </div>
  )
}

export default RefundPolicyModal